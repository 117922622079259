import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const PartListPage = lazy(() => import('src/pages/part/list'));
const PartCreatePage = lazy(() => import('src/pages/part/new'));
const PartEditPage = lazy(() => import('src/pages/part/edit'));

// ----------------------------------------------------------------------

const guardedPartListPage = (
  <RoleBasedGuard navigateTo roles={['fleet.nobody']}>
    <PartListPage />
  </RoleBasedGuard>
);

const guardedPartCreatePage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <PartCreatePage />
  </RoleBasedGuard>
);

const guardedPartEditPage = (
  <RoleBasedGuard navigateTo roles={['fleet.mechanic']}>
    <PartEditPage />
  </RoleBasedGuard>
);

export const partRoutes = [
  {
    path: 'part',
    children: [
      { element: guardedPartListPage, index: true },
      { path: 'new', element: guardedPartCreatePage },
      { path: ':id/edit', element: guardedPartEditPage },
    ],
  },
];
