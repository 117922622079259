export const STATUS_OPTIONS = [
  {
    value: 'all',
    label: 'All',
    viewOnly: true,
  },
  {
    value: 'open',
    label: 'Not Started',
  },
  {
    value: 'active',
    label: 'In Progress',
  },
  {
    value: 'pending',
    label: 'Pending Review',
  },
  {
    value: 'complete',
    label: 'Complete',
  },
];

export const SECTION23_OPTIONS = {
  B: 'Ineligible',
  Y: 'Eligible',
  C: 'Unknown',
};

export const RELATIONSHIP_OPTIONS = [
  'Mother',
  'Father',
  'Stepmother',
  'Stepfather',
  'Grandmother',
  'Grandfather',
  'Foster Father',
  'Foster Mother',
  'Aunt',
  'Uncle',
  'Brother',
  'Sister',
  'Legal Guardian',
  'Neighbor',
  'Family Friend',
  'Other',
];
