import { useMemo, useState } from 'react';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import axiosInstance, { API_ENDPOINTS, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export function useGetStudents() {
  const URL = API_ENDPOINTS.student.list;

  const [lastValidated, setLastValidated] = useState(null);

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher, {
    onSuccess: () => {
      setLastValidated(new Date());
    },
  });

  const memoizedValue = useMemo(
    () => ({
      students: data,
      studentsLoading: isLoading,
      studentsError: error,
      studentsValidating: isValidating,
      studentsEmpty: !isLoading && !data.length,
      lastValidated,
      revalidateStudents: mutate,
    }),
    [data, error, isLoading, isValidating, lastValidated, mutate]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetStudent(studentId, expand) {
  const URL = `${API_ENDPOINTS.student.details(studentId)}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, () =>
    fetcher([URL, { params: { expand: expand.join(',') } }])
  );

  const memoizedValue = useMemo(
    () => ({
      student: data,
      studentLoading: isLoading,
      studentError: error,
      studentValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useUpdateStudentAddress(studentId) {
  const URL = `${API_ENDPOINTS.student.details(studentId)}/address`;

  const updateStudentAddress = async (address) => {
    const response = await axiosInstance.put(URL, { ...address });

    return response;
  };

  return updateStudentAddress;
}

export function useUpdateDiplomaChecklist(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateDiplomaChecklist = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { diplomaChecklist: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateDiplomaChecklist);

  const memoizedValue = useMemo(
    () => ({
      updateDiplomaChecklist: trigger,
      error,
      isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateCertificateChecklist(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateCertificateChecklist = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { certificateChecklist: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateCertificateChecklist);

  const memoizedValue = useMemo(
    () => ({
      updateCertificateChecklist: trigger,
      error,
      isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateCareerChecklist(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateCareerChecklist = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { careerChecklist: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateCareerChecklist);

  const memoizedValue = useMemo(
    () => ({
      updateCareerChecklist: trigger,
      error,
      isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateLearningPathway(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateLearningPathway = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { learningPathway: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateLearningPathway);

  const memoizedValue = useMemo(
    () => ({
      updateLearningPathway: trigger,
      error,
      isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateInterests(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateInterests = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { interests: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateInterests);

  const memoizedValue = useMemo(
    () => ({
      updateInterests: trigger,
      error,
      isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateCourses(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateCourses = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { courses: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateCourses);

  const memoizedValue = useMemo(
    () => ({
      updateCourses: trigger,
      error,
      isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateComments(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateComments = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { comments: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateComments);

  const memoizedValue = useMemo(
    () => ({
      updateComments: trigger,
      updateCommentsError: error,
      updateCommentsMutating: isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateVisitLogs(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateVisitLogs = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { visitLogs: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateVisitLogs);

  const memoizedValue = useMemo(
    () => ({
      updateVisitLogs: trigger,
      updateVisitLogsError: error,
      updateVisitLogsMutating: isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useUpdateHighSchoolTrack(studentId) {
  const URL = API_ENDPOINTS.student.details(studentId);

  const updateHighSchoolTrack = async (url, { arg }) => {
    const result = await axiosInstance.put(URL, { highSchoolTrack: arg });
    return result.data;
  };

  const { trigger, error, isMutating } = useSWRMutation(URL, updateHighSchoolTrack);

  const memoizedValue = useMemo(
    () => ({
      updateHighSchoolTrack: trigger,
      error,
      isMutating,
    }),
    [trigger, error, isMutating]
  );

  return memoizedValue;
}

export function useGetInterests() {
  const URL = API_ENDPOINTS.student.interestsList;

  const { data, isLoading, error, isValidating, mutate } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      interestsList: data,
      interestsListLoading: isLoading,
      interestsListError: error,
      interestsListValidating: isValidating,
      revalidateInterestsList: mutate,
    }),
    [data, error, isLoading, isValidating, mutate]
  );

  return memoizedValue;
}
