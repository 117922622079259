import { Suspense, lazy } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// config
//
import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { partRoutes } from './part';
import { profileReviewRoutes } from './profile-review';
import { enrollmentRoutes } from './enrollment';
import { purchaseOrderRoutes } from './purchase-order';
import { routingRequestRoutes } from './routing-request';
import { crossboundaryRequestRoutes } from './crossboundary-request';
import { studentRoutes } from './student';
import { vehicleRoutes } from './vehicle';
import { workOrderRoutes } from './workorder';
import { careerRoutes } from './career';

// ----------------------------------------------------------------------

// DASHBOARD
const DashboardPage = lazy(() => import('src/pages/dashboard'));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { element: <DashboardPage />, index: true },
        ...studentRoutes,
        ...profileReviewRoutes,
        ...enrollmentRoutes,
        ...vehicleRoutes,
        ...partRoutes,
        ...workOrderRoutes,
        ...purchaseOrderRoutes,
        ...routingRequestRoutes,
        ...crossboundaryRequestRoutes,
        ...careerRoutes,
      ],
    },

    // Auth routes
    ...authRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
