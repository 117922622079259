import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';
import { ROLES } from 'src/constants';

// ----------------------------------------------------------------------

const StudentListPage = lazy(() => import('src/pages/student/list'));
const StudentProfilePage = lazy(() => import('src/pages/student/profile'));

// ----------------------------------------------------------------------

const guardedStudentListPage = (
  <RoleBasedGuard navigateTo roles={[ROLES.SCHOOL_ADMIN, ROLES.USER_ROLE_COUNSELOR]}>
    <StudentListPage />
  </RoleBasedGuard>
);

export const studentRoutes = [
  {
    path: 'student',
    children: [
      { element: guardedStudentListPage, index: true },
      { path: ':asn', element: <StudentProfilePage /> },
    ],
  },
];
