import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { bgGradient } from 'src/theme/css';

import Header from '../_common/header-simple';

// ----------------------------------------------------------------------

export default function SimpleLayout({ children }) {
  return (
    <Stack
    flexGrow={1}
    minHeight='100vh'
     sx={{
      ...bgGradient({imgUrl: '/assets/background/overlay_2.jpg', color: 'rgba(255, 255, 255, 0.88)'}),
     }}
     >

      <Header />

      {children}
    </Stack>
  );
}

SimpleLayout.propTypes = {
  children: PropTypes.node,
};
