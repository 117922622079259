import { lazy } from 'react';
import { RoleBasedGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const CareerProfilePage = lazy(() => import('src/pages/career/profile'));
const BusinessesPage = lazy(() => import('src/pages/career/businesses'));

// ----------------------------------------------------------------------

const guardedCareerProfilePage = (
  <RoleBasedGuard navigateTo roles={[]}>
    <CareerProfilePage />
  </RoleBasedGuard>
);

const guardedBusinessesPage = (
  <RoleBasedGuard navigateTo roles={[]}>
    <BusinessesPage />
  </RoleBasedGuard>
);

export const careerRoutes = [
  {
    path: 'careers',
    children: [
      { path: 'profiles', children: [{ path: ':profileId', element: guardedCareerProfilePage }] },
      { path: 'businesses', element: guardedBusinessesPage },
    ],
  },
];
